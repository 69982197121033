(function(JsToolkit) {
  var ModalClass = JsToolkit.components.Modal;

  var modal = document.querySelector('[data-component="Modal"]');

  var bookDownload = document.querySelector('.bookDownload');

  if (modal !== null) {
    var popin = new ModalClass(modal);
    popin.$mount();

    if (bookDownload !== null) {
      bookDownload.addEventListener('click', function() {
        popin.open();
      });
    }
  }
})(JsToolkit)